
/* .panelContainer {
  position: absolute;
  height: 100vh;
  display: flex;
  align-items: center;
} */
/* noto-sans-200 - latin */

.panelContainer {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1;
  top: 60px;
}
.treeViewRoot{
  margin-left: 10px;
}
.MuiTreeView-root{
  margin-left: 20px;
}
.showAllStores{
  /* margin-left: 5px; */
  flex-direction: column;
  font-size:12px;
  /* font-family: 'Noto Sans'; */
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.showFilter{
  margin-left: 5px;
}
.iconTitle{
  flex-direction: "column" !important;
  font-size:"12px" !important;
  /* font-family: "Noto Sans"; */
  display: flex;
}
.showTopBar{
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  z-index: 1;
  position: relative;
  float: left;
  left: 30%;
  padding: 5px;
  /* width: 100vw; */
  background-color: white;
}
/* .showTopBarMobile
{
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  position: relative;
  float: left;
  left: 1%;
  padding: 5px;
  background-color: white;
} */
.showTopBarMobile
{
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  position: relative;
  float: left;
  left: 1%;
  /* padding: 5px; */
  width: 90vw;
  background-color: white;
}
.showSearchMobile{
  width: inherit;
}
.mobileIconBorderDesign{
  border: 1px solid gray;
    background-color: white;
    position: relative;
    top: 5px;
    margin: 5px;
    padding: 5px;
    right: 5px;
    border-radius: 10%;
}
.searchContainer{
  position: absolute;
  /* height: 100vh; */
  top:10px;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.showMobileIcon{
  display: flex;
    flex-direction: row;
    width: 100vw;
    position: relative;
    top: 10px;
    right: 10px;
    /* margin: 0px; */
    justify-content: flex-end;
}


.searchAlignment{
  z-index: 1;
  background-color: white;
}
/* .panel {
  display: flex;
  flex-direction: column;
  align-items: flex;
  z-index: 1;
  padding: 10px;
  margin: 10px;
  height: 70vh;
  width: 20vw;
  min-width: 250px;
} */
.panel {
  display: flex;
  flex-direction: column;
  align-items: flex;
  z-index: 1;
  /* padding: 10px;   */
  width: 24vw;
  height: 92vh;
  min-width: 300px;
}
.searchPanel{
  z-index: 1;
}
.fabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab {
  position: fixed;
  top: 0;
  margin-top: 10px;
}

.closeDrawer {
  height: 20px;
  min-height: 20px;
}
/* .cardInfoText:before {
  content: "";
  height: 30px;
  width: 4px;
  background-color: green;
  position: absolute;
  top: 15px;
  left: 0;
} */
#insideInfoWindow{
  width: 24vw;
}
.drawerHeader{
  background-color: black;
    color: white;
    text-align: center;
    /* height: 40px; */
    display: flex;
    padding: 20px;
}
.drawerPaper {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 100%;
}

.scrollingCards {
  overflow-y: scroll;
}

.cardButton {
  width: 100%;
}

.cardInfoText {
  width: 100%;
  padding: 10px;
  font-size: 101%;
  text-align: left;
  line-height: 20px;
}

.infoWindowText {
  /* font-family: 'Noto Sans' !important; */
  font-size: 110%;
  line-height: 20px;
  font-weight: 400;
}

.storeInfoCard {
  margin: 10px;
}

.searchInput {
  margin: 10px;
  min-height: 32px;
}

.checkbox {
  margin-left: auto;
  margin-right: auto;
}
.filterFooter{
  position: absolute;
    bottom: 0px;
    width: 100%;
}
.footerButton{
  display: flex;
  padding: 15px;
  bottom: 0px;
  justify-content: space-around;
  border-top: 1px solid gray;
  -webkit-box-shadow: 0px -4px 3px rgb(50 50 50 / 25%);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -4px 3px rgb(50 50 50 / 25%);
}
.checkboxMobile {
  margin-left: auto;
  margin-right: auto;
  flex: 1;
}

h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
  z-index: 0!important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  z-index: 0!important;
}
.css-cktaikv{
  z-index: 0;
}

/* Scroll Bar */
::-webkit-scrollbar-track {
  cursor: pointer;
  border-radius: 5px;
  background: #C1C5C8;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 3px 2px 6px #238C4E;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

@media only screen and (max-width: 55.75em) {
  .showTopBarMobile{
    left: 5%;
  }
  .showMobileIcon{
    right: 10px;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 280px) and (max-width: 300px) {
  .showTopBarMobile{
    left: 1%;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 414px) and (max-width: 450px) {
  .showTopBarMobile{
    left: 5%;
  }
  .showMobileIcon{
    right: 10px;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 350px) and (max-width: 400px) {
  .showTopBarMobile{
    left: 5%;
  }
  .showMobileIcon{
    right: 10px;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 393px) and (max-width: 400px) {
  .showTopBarMobile{
    left: 5%;
  }
  .showMobileIcon{
    right: 10px;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .showTopBarMobile{
    left: 5%;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 912px) and (max-width: 1368px) {
  .showTopBarMobile{
    left: 5%;
  }
  .showMobileIcon{
    right: 40px;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 800px) and (max-width: 999px) {
  .cardInfoText {
    width: 100%;
  }
  #insideInfoWindow{
    width: auto;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .cardInfoText {
    width: 100%;
  }
  #insideInfoWindow{
    width: 24vw;
  }
}
@media (min-width: 1200px) and (max-width: 1259px) {
  .cardInfoText {
    width: 100%;
  }
  /* #insideInfoWindow{
    width: 24vw;
  } */
}
@media (min-width: 1260px) and (max-width: 1399px) {
  .cardInfoText {
    width: 100%;
  }
  #insideInfoWindow{
    width: 24vw;
  }
}
@media (min-width: 1400px) and (max-width: 1450px) {
  .cardInfoText {
    width: 100%;
  }
  #insideInfoWindow{
    width: 24vw;
  }
}
